/* eslint-disable prefer-rest-params */
import { useEffect } from 'react';

import { getGoogleAnalyticsScriptApi } from './get-google-analytic-script.api';

type WindowWithGA = Window &
  typeof globalThis & {
    dataLayer: [];
    gtag: (...input: (string | Date)[]) => void;
  };

const SCRIPT_ID = 'tt-gg-gtag-custom';

export const useGoogleAnalyticV4 = (isAppStable: boolean | undefined, delay = 2000) => {
  useEffect(() => {
    if (!isAppStable) return;
    if (document.getElementById(SCRIPT_ID) != null) return;

    setTimeout(() => {
      getGoogleAnalyticsScriptApi()
        .then(response => {
          const { scriptContent, gaId } = response;
          if (!scriptContent || !gaId) return;

          const script: HTMLScriptElement = document.createElement('script');
          script.id = SCRIPT_ID;
          script.type = 'text/javascript';
          script.async = true;
          script.textContent = response.scriptContent;
          document.head.appendChild(script);

          (window as WindowWithGA).dataLayer = (window as WindowWithGA).dataLayer || [];
          (window as WindowWithGA).gtag = function gtag() {
            (window as any).dataLayer.push(arguments);
          };

          (window as WindowWithGA).gtag('js', new Date());
          (window as WindowWithGA).gtag('config', gaId);
        })
        .catch(error => {
          console.warn('[gg-gtag/GoogleAnalyticV4Custom] on load script: ', error);
        });
    }, delay);
  }, [isAppStable, delay]);
};
